/**
 * 主设备
 */
 import * as echarts from 'echarts'
 import healthSourceJs from "./health-source";
 import healthDataJs from "./health-data";
 import healthTcmJs from "./health-tcm";
 import healthCapabilityJs from "./health-capability";
 import session from "../../../../store/store";

export default {

    data() {
        return {
           userNumber:5645,
           type:0, //1:全屏
           leftTitle:'',
           centerTitle:'',
           tableData: [
            {name:'Lyre',projectName:'血压',state:'理想',time:'2022-10-12 19:48'},
            {name:'Lyre',projectName:'血糖',state:'正常',time:'2022-10-11 19:48'},
            {name:'Lyre',projectName:'血压',state:'高血压',time:'2022-10-11 15:48'},
            {name:'Lyre',projectName:'尿酸',state:'正常',time:'2022-10-10 19:48'},
            {name:'Lyre',projectName:'血氧',state:'正常',time:'2022-10-10 09:38'},
            {name:'Lyre',projectName:'体温',state:'正常',time:'2022-10-10 09:29'},
            {name:'Lyre',projectName:'血脂',state:'正常',time:'2022-10-10 08:48'},
            {name:'Lyre',projectName:'糖化',state:'偏高',time:'2022-10-09 16:40'},
            {name:'Lyre',projectName:'血糖',state:'偏高',time:'2022-10-08 19:48'},
            {name:'Lyre',projectName:'尿液',state:'阴性',time:'2022-10-08 14:30'},
            {name:'Lyre',projectName:'尿酸',state:'偏高',time:'2022-10-07 19:48'},
            {name:'Lyre',projectName:'血压',state:'高血压',time:'2022-10-07 19:48'},
            {name:'Lyre',projectName:'血压',state:'理想',time:'2022-10-06 19:48'},
           ],
        };
    },
    mounted() {
      this.init();
      if(this.$route.query.type != null) {
        this.type = this.$route.query.type;
        document.title = session.getSysName() + '-' + '大数据中心'
      }

      if(0 == this.type) {
        this.leftTitle = '';
        this.centerTitle = '健 康 报 表';
      }else if(1 == this.type) {
        this.leftTitle = '健康报表';
        this.centerTitle = '大 数 据 中 心';
      }
    },
    methods: {
      init() {
        healthSourceJs.setHealthSourceChart(echarts);
        healthTcmJs.setHealthTcmChart(echarts);
        healthCapabilityJs.setHealthCapabilityChart(echarts);
        healthDataJs.setHealthDataChart(echarts);
      },

      rowStyle() {
        return 'color:white';
      },
      tableRowClassName() {
        return 'report-row';
      },
      magnify() {
        const routeData = this.$router.resolve({
            path: "/health-report-full-screen",
            query: {
                type: 1     //全屏
             } 
          })
        window.open(routeData.href)
      }
    }
};