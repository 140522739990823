/**
 * 设备类型
 */

// import * as echarts from 'echarts'

export default {
    setHealthDataOption(echarts, option) {
        const doc = document.getElementById("healthDataType")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setHealthDataChart(echarts) {
        var option = {
            tooltip: {
              backgroundColor: "rgba(255,255,255,0.8)",
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              textStyle: {
                color: "#fff",
              },
            },
            grid: {
              left: '1%',
              right: '1%',
              top: '12%',
              bottom: '1',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: ['血压','血糖','血氧','体温','血脂','尿酸','尿常规','糖化血红','心电','体脂'],
                axisTick: {
                  alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {           
                        color: '#fff'
                    }
                  }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine:{
                  show: false
                },
                axisLine: {
                    lineStyle: {           
                        color: '#fff'
                    }
                  }
              }
            ],
            series: [
              {
                name: '数量',
                type: 'bar',
                barWidth: '25%',
                data: [800, 780, 700, 650, 560, 500, 410, 389, 234, 123],
                itemStyle: {
                    color: '#FECC43'
                  }
              }
            ]
          };

        this.setHealthDataOption(echarts, option);
    },
};